














import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class SavePipeLineButton extends Vue {
    @Prop({default: false})
    public loanIsLocked: boolean;


    public handlePageReload() {
        location.reload();
        return false;
    }
}
