import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL, EventBus } from "@/config";
import { io } from 'socket.io-client';
import SavePipeLineButton from "../views/SavePipeLineButton.vue";

@Component({ components: { SavePipeLineButton }})
export default class PipeLineSocketMixin extends Vue {
    public loanIsLocked = false;
    public socket = null;
    public loanTxnId = null;
    public taskNameForRoom = '';
    
  
    public saveEvent() {
        let userInfo = this.$store.state.sessionObject.userInfo.firstName + ' ' + this.$store.state.sessionObject.userInfo.lastName;
        this.socket.emit('send-room-message', {roomID: this.loanTxnId + '/' + this.taskNameForRoom, msg: userInfo});
    }
  
    mounted() {
        if (!this.loanTxnId) {
          this.loanTxnId = this.$route.query.id;
        }
        //global connection to server
        let serverDomain = BASE_API_URL.replace(/\/$/, '');
        io(serverDomain);
        //use namespace
        this.socket = io(`${serverDomain}/los-editting`);
    
        //event after connection to server
        this.socket.on("connect", () => {
          //emit custom event to server, create room - server will create a new room if it not exists
          this.socket.emit('create-room', this.loanTxnId + '/' + this.taskNameForRoom); 
        });
     
        //handle incoming event with name 'incoming-room-message', this event is emitted by server for room sockets (except initiator socket)
        this.socket.on('incoming-room-message', (msg) => {
          this.loanIsLocked = true; 
          EventBus.$emit('docUpdatedBy', msg);
          this.$snotify.info(`${msg} updated this task. Please reload before editing and saving.`);
        });
    }
    
    beforeDestroy() {
    if (this.socket) {
        this.socket.disconnect();
        }
    }

}
