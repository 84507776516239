
























































import { Component, Vue, Prop } from "vue-property-decorator";
import DisplayFiles from "@/views/DisplayDocument.vue";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";

@Component({ components: { DisplayFiles } })
export default class AddComment extends Vue {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  
  @Prop()
  public commentData;

  @Prop({default: true,})
  public showComment;
  
  @Prop()
  public ifCommentRequired;

  @Prop({default: false})
  public loanIsLocked: boolean;

  public async uploadCommentAttachment(event) {
    try {
      if (event.target.files.length > 0) {
        this.commentData.commentAttachment = [];
        Object.values(event.target.files).forEach(element => {
          this.commentData.commentAttachment.push(element);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async displayCommentAttachment(commentAttachment ,ifDelete) {
    try {
      if (commentAttachment[0].hasOwnProperty("path")) {
        this.$refs.dfc["setInitialValuesForShowingSamplesWithDeleteButton"](
          // "View Document",
          commentAttachment, ifDelete, null
        );
      } else {
        this.$refs.dfc["setInitialValuesForLocalFiles"](commentAttachment, ifDelete, null);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public displayComment(comment, files){
    if(files) this.$refs.dfc["setInitialValuesForAttachmentsAndComments"](comment, files);
    else  this.$refs.dfc["setInitialValuesForShowMessage"](comment);
  }


  public async validateComment(ifSave){
    return ifSave?true:await this.$validator.validateAll();
  }

  public deleteDoc(obj,fileName){
    obj['fileName'] = fileName
    this.$emit('deleteDoc', obj);
  }

  public deleteFile(obj){
    this.$emit('deleteInvoice');
  }

}
