/**
 * For Task Loan Setup Class
 * @createdOn 31 Jan 2020 , 11:35 am Friday
 */


export class LoanSetup {
    constructor() {
        this.borrowerCondition = new Array<borrowerCondition>()
        this.thirdPartyCondition = new Array<thirdPartyCondition>()
        this.commentData = new commentData();
        this.options = new loanSetupOptions();
        this.isBypassCreditAuthorization = false;
        this.bypassCreditAuthorizationComment = null;
        this.loanEstimate = new loanEstimateOrCreditAuthorization();
        this.creditAuthorization = new loanEstimateOrCreditAuthorization();
    }
    public borrowerCondition: Array<borrowerCondition>
    public thirdPartyCondition: Array<thirdPartyCondition>
    public commentData: commentData
    public options: loanSetupOptions
    public isBypassCreditAuthorization: boolean;
    public bypassCreditAuthorizationComment: string;
    public loanEstimate: loanEstimateOrCreditAuthorization;
    public creditAuthorization: loanEstimateOrCreditAuthorization;

}


class loanSetupOptions {
    constructor() {
        this.initialPhoneCall = false;
        this.propertyTax = false;
        this.subjectAddressMatches = false;
        this.completed1003 = false;
        this.addendumsGetHOA = false;
        this.liabilities = false;
        this.childSupport = false;
    }
    public initialPhoneCall: boolean
    public propertyTax: boolean
    public subjectAddressMatches: boolean
    public completed1003: boolean
    public addendumsGetHOA: boolean
    public liabilities: boolean
    public childSupport: boolean
}


class loanEstimateOrCreditAuthorization {
    constructor() {
        this.documents = []
    }
    public documents: Array<any>;
    public status: String;
    public byPassComment: String;
    public documentUploadStatus: String;
    public rejectReason: String;
}

/**
 * For Task Run Aus Exporting this class
 * @createdOn 31 Jan 2020 , 11:40 am Friday
 * @createdBy Pratik Chauhan
 * -------------------------------------------------------
 * @modifiedOn 14 Feb 2020, 04:13 am Friday
 * @modifier Bug
 */
export class runAus {
    constructor() {
        this.commentData = new commentData();
        this.options = new runAusOptions();
        this.copyOfDUOrLPFindings = [];
    }
    public commentData: commentData;
    public ausOption: String;
    public options: runAusOptions;
    public copyOfDUOrLPFindings :Array<any>;
    public loanNumber: string;
}

class runAusOptions {
    public hasExported: Boolean;
    public hasImported: Boolean;
    public hasRegisterWithLender: Boolean;
    public hasReviewed1003: Boolean;
    public hasUploadedDocuments: Boolean;
}

/**
 * For Task Initial Disclosure Exporting this class
 * @createdOn 3 Feb 2020 , 2:30 pm Friday
 *
 */
export class initialDisclosure {
    constructor() {
        this.commentData = new commentData();
        this.options = new initialDisclosureOptions();
        this.loanEstimate = new loanEstimateInInitialDisclosure();
        this.creditAuthorization = new loanEstimateOrCreditAuthorization();
        this.lenderInitialDisclosure = []
    }
    public commentData: commentData;
    public options: initialDisclosureOptions;
    public creditAuthorization: loanEstimateOrCreditAuthorization;
    public loanEstimate: loanEstimateInInitialDisclosure;
    public lenderInitialDisclosure : Array<any>;
}

class loanEstimateInInitialDisclosure {
    constructor() {
        this.documents = [];
    }
    public documents: Array<any>
    public rejectReason: String;
    public status: String;
    public documentUploadStatus: String;
    public byPassComment: String;
}

class initialDisclosureOptions {

    constructor() {
        this.importedFees = false;
        this.populatedFees = false;
        this.hasInitialDisclosureSend = false;
        this.disclosureSigned = false;
        this.submittedToUnderwriting = false;
    }
    public importedFees: boolean
    public populatedFees: boolean
    public hasInitialDisclosureSend: boolean
    public disclosureSigned: boolean
    public submittedToUnderwriting: boolean
}

/**
 * For Task Denail  Exporting this class
 * @createdOn 3 Feb 2020 , 2:30 pm Friday
 *
 */
export class Denial {
    constructor() {
        this.commentData = new commentData();
        this.options = new denialOptions();
        this.denialLetter = [];

    }
    public commentData: commentData;
    public options: denialOptions;
    public denialLetter: Array<any>;
}

class denialOptions {

    constructor() {
        this.hasContactedBorrower = false;
        this.hasContactedMlo = false;
        this.hasUploadDenial = false;
    }
    public hasContactedBorrower: boolean
    public hasContactedMlo: boolean
    public hasUploadDenial: boolean
}
export class Closing {
    constructor() {
        this.commentData = new commentData();
        this.options = new closingOptions();
        this.finalClosingDisclosure = [];
        this.copyOfNote = [];
        this.copyOfMortgage = [];
    }
    public closingDisclosure: any;
    public commentData: commentData;
    public options: closingOptions;
    public finalClosingDisclosure: Array<any>;
    public copyOfNote: Array<any>;
    public copyOfMortgage: Array<any>;
}

class closingOptions {

    constructor() {
        this.sendInstructionToSA = false;
        this.initiateBrokerCompliancePackage = false;
        this.isDisclosureFile = false
    }
    public sendInstructionToSA: boolean
    public initiateBrokerCompliancePackage: boolean
    public isDisclosureFile: boolean
}
/* For Conditional Approval Task
* @createdOn 10 Feb 2020 , 3:30 pm Monday
*/
export class ConditionalApproval {
    constructor() {
        this.commentData = new commentData();
        this.options = new conditionalApprovalOptions();
        this.borrowerCondition = new Array<borrowerCondition>()
        this.thirdPartyCondition = new Array<thirdPartyCondition>()
        this.updatedStampApprovalDate =""
        this.lenderApprovalOrCommitmentLetter = []
    }
    public commentData: commentData;
    public options: conditionalApprovalOptions;
    public lenderApprovalOrCommitmentLetter : Array<any>
    public updatedStampApprovalDate: string;
    public borrowerCondition: Array<borrowerCondition>
    public thirdPartyCondition: Array<thirdPartyCondition>
}

class conditionalApprovalOptions {
    constructor() {
        this.hasContactedBorrower = false;
        this.hasDisclosuresInitiated = false;
        this.submittedToUnderwriter = false;
    }
    public hasContactedBorrower: boolean;
    public hasDisclosuresInitiated: boolean;
    public submittedToUnderwriter: boolean;
}

/* For Conditional Approval Task
* @createdOn 12 Feb 2020 , 5:30 pm Monday
*/
export class Suspended {
    constructor() {
        this.commentData = new commentData();
        this.options = new SuspendedOptions();
        this.borrowerCondition = new Array<borrowerCondition>()
        this.thirdPartyCondition = new Array<thirdPartyCondition>()
    }
    public commentData: commentData;
    public options: SuspendedOptions;
    public borrowerCondition: Array<borrowerCondition>
    public thirdPartyCondition: Array<thirdPartyCondition>
}

class SuspendedOptions {
    constructor() {
        this.hasContactedBorrower = false;
        this.hasConnectedMLO = false;
        this.uploadSuspenseLetter = false;
    }
    public hasContactedBorrower: boolean;
    public hasConnectedMLO: boolean;
    public uploadSuspenseLetter: boolean;
}

export class PendingFunding {
    constructor() {
        this.commentData = new commentData();
        this.options = new pendingFundingOptions();
        this.currentStep = 1;
        this.brokerCheck =[]
        this.wemloCheck = []
        this.updatedFundedDate =""
        this.updatedClosingDate = "";
        this.complianceData = {}

    }
    public complianceData: any
    public commentData: commentData;
    public additionalTaskCount: number;
    public brokerCheck: Array<any>
    public wemloCheck: Array<any>
    public options: pendingFundingOptions;
    public updatedFundedDate: string
    public updatedClosingDate: string;
    public currentStep: number;
}

class pendingFundingOptions {
    constructor() {
        this.signedPackageReceived = false;
        this.hasClosingDisclosureInitiated = false;
        this.callToMlo = false;
    }
    public signedPackageReceived: boolean;
    public hasClosingDisclosureInitiated: boolean;
    public callToMlo: boolean;
}

export class ClearToClose {
    constructor() {
        this.commentData = new commentData();
        this.options = new clearToCloseOptions();
        this.creditReportInvoice = [];
        this.rateLock = [];

    }
    public wemloInvoice: any;
    public creditReportInvoice: Array<any>;
    public rateLock: Array<any>;
    public commentData: commentData;
    public options: clearToCloseOptions;
}

class clearToCloseOptions {
    constructor() {
        this.hasClosingInitiated = false;
        this.hasContactedBorrower = false;
        this.hasContactedMlo = false;
        this.hasContactedCompany = false;
    }
    public hasClosingInitiated: boolean;
    public hasContactedBorrower: boolean;
    public hasContactedMlo: boolean;
    public hasContactedCompany: boolean;
}


export class Appraisal {
    constructor() {
        this.commentData = new commentData();
        this.options = new appraisal();
        this.creditAuthorization = new loanEstimateOrCreditAuthorization

    }
    public commentData = new commentData();
    public options = new appraisal();
    public vendors: Array<any>
    public wemloVendors: Array<any>
    public creditAuthorization: loanEstimateOrCreditAuthorization;

}

export class appraisal {
    constructor() {
        this.isWemloVendor = false
        this.taskOptions = null
        this.vendorId = null
    }
    public isWemloVendor: boolean;
    public vendorId: number;
    public taskOptions: string

}



export class Hoi {
    constructor() {
        this.commentData = new commentData();
        this.options = new hoi();
    }
    public commentData = new commentData();
    public options = new hoi();
}

class hoi {
    constructor() {
        this.loanNumber = false
        this.mortgageClause = false
        this.purchaseAgreement = false
        this.sufficientCoverage = false
    }
    public loanNumber: boolean;
    public mortgageClause: boolean;
    public purchaseAgreement: boolean;
    public sufficientCoverage: boolean;

}
export class OrderTitle {
    constructor() {
        this.commentData = new commentData();
        this.options = new orderTitle();
        this.borrowerContact = new borrowerContact()
    }
    public commentData = new commentData();
    public options = new orderTitle();
    public borrowerContact = new borrowerContact()
}
class borrowerContact {
    constructor() {
        this.email = null
        this.companyName = null
        this.phone = null
    }
    public email: string
    public companyName: string
    public phone: number
}
class orderTitle {
    constructor() {
        this.hasTitleOrdered = false;
    }
    public hasTitleOrdered: boolean;
}


export class Voe {
    constructor() {
        this.uploadAuthorization = [];
        this.uploadForm = [];
        this.options = new voe();
    }
    public uploadAuthorization: Array<any>;
    public uploadForm: Array<any>;
    public options : voe;
}

class voe {
    constructor() {
        this.methodToSend = null;
        this.typeToSend = null;
        this.formToSend = null;
        this.notVoeNumber = false
    }
    public methodToSend: string;
    public typeToSend: string;
    public formToSend: string;
    public notVoeNumber: Boolean;
}

export class VoeResponse {
    constructor() {
        this.commentData = new commentData();
        this.uploadSignedFile = [];
        this.options = new voeResponse();
    }
    public uploadSignedFile:Array<any>;
    public options = new voeResponse();
    public commentData = new commentData();
}

class voeResponse {
    constructor() {
        this.isVoeComplete = null;
        this.isVoeSigned = null;
    }
    public isVoeComplete:boolean;
    public isVoeSigned: boolean;
}

export class AppraisalReceived {
    constructor() {
        this.commentData = new commentData();
        this.options = new appraisalReceived();
        this.appraisalReportInvoice = []
    }
    public commentData = new commentData();
    public options = new appraisalReceived();
    public appraisalReportInvoice: Array<any>;

}

class appraisalReceived {
    constructor() {
        this.taskOption = null;

    }
    public taskOption: string;
    public appraisalValue: number;
    public appraisalPaidDate: string;
    public appraisalAssignedToOrderDate: string;
    public reportDate: string;
    public inspectionDate: string;
    public appraisalPaidConfirmed: boolean;
    public appraiserAssignedToOrder: boolean;
    public reportDateConfirmed: boolean;
    public inspectionDateConfirmed: boolean;
}

export class TitleReceived {
    constructor() {
        this.commentData = new commentData();
        this.options = new titleReceivedOptions();
        this.titleReportInvoice =[]
    }
    public titleReportInvoice: Array<any>
    public commentData: commentData;
    public options: titleReceivedOptions;
}
class titleReceivedOptions {
    constructor() {
        this.cpl = new cpl();
        this.commitment = new commitment();
        this.otherObject = new otherObject();
    }
    public cpl: cpl;
    public commitment: commitment;
    public otherObject: otherObject;
}
class cpl {
    constructor() {
        this.loanNumber = false
        this.dateCorrect = false
        this.mortgageeClause = false

    }
    public loanNumber: boolean
    public mortgageeClause: boolean
    public dateCorrect: boolean
}
class commitment {
    constructor() {
        this.lenderPolicyMatchesLoanAmount = false
        this.mortgageeClause = false
        this.chainOfTitle = false
    }
    public lenderPolicyMatchesLoanAmount: boolean
    public mortgageeClause: boolean
    public chainOfTitle: boolean
}
class otherObject {
    constructor() {
        this.eAndOPolicyCurrent = false
        this.wiringReceived = false
        this.taxCertificationReceived = false
        this.preCdReceived = false
    }
    eAndOPolicyCurrent: boolean
    wiringReceived: boolean
    taxCertificationReceived: boolean
    preCdReceived: boolean
}
/**
 * Common Data Structure for Tasks
 * @createdOn 31 Jan 2020 , 11:30am Friday
 *
 */
export class CustomTask {
    constructor(){
        this.taskName =null
        this.type =null
        this.teamMenmber =[]
        this.reminder = null
        this.time =new time()
        this.selectDate = null
        this.description =null
        this.borrower=null
        this.attachments =[]
    }
    public taskName : string
    public type:string
    public teamMenmber:Array<any>
    public borrower:string
    public selectDate:Date
    public time:time
    public reminder:string
    public description:string
    public attachments:Array<any>
    public addedBy: string
}
class time{
    constructor(){
        this.dayNight ='AM'
        this.hours =null
        this.minutes =null
    }
    public hours:number
    public minutes:number
    public dayNight:string
}

export class borrowerCondition {
    constructor() {
        this.samples = []
        this.fileHistory = []
    }
    public categoryName: string
    public hasApprovedToSubmit: boolean
    public samples: Array<any>
    public fileHistory: Array<any>;
    public documentId: String;
}

export class thirdPartyCondition {
    constructor() {
        this.categoryType = null;
        this.samples = [];
        this.fileHistory = [];
    }
    public categoryName: string
    public categoryType: string
    public otherCategoryType: string;
    public hasApprovedToSubmit: boolean
    public samples: Array<any>
    public fileHistory: Array<any>;
    public documentId: String;
}

class commentData {
    constructor() {
        this.commentAttachment = [];
        this.showCommentToBroker = false
        this.comment = null
    }
    public comment: string
    public commentAttachment: Array<any>
    public showCommentToBroker: boolean
}