

import { Component } from "vue-property-decorator";
import AddComment from "@/views/AddComment.vue";
import { Voe } from "@/models/tasks.model";
import { BASE_API_URL,EventBus } from "../../../../../config";
import Axios from "axios";

import DisplayFiles from "@/views/DisplayDocument.vue";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import CommonMixin from "@/mixins/common.mixin";

import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import { mixins } from "vue-class-component";



@Component({ components: { AddComment,DisplayFiles } })
export default class VoeComponent extends mixins(PipeLineSocketMixin, CommonMixin) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public voe = new Voe();
  public pipeLineHeaderData: any = {};
  public loanTxnId = null;
  public taskNameForRoom = 'voe';

   private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "Voe", videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/VOE.mp4' });
  }
  public async fetchVoeTaskDetail() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "additionalTask/fetchVoeTaskDetail",
        { loanTxnId: this.$route.query.id });
      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.voe = response.data.voe;
      this.$store.state.wemloLoader = false;
      
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  public async submit(ifSave) {
    try {
      //  let validComment = await this.$refs.comment['validateComment'](ifSave);
      await this.$validator.validateAll();
      this.$store.state.wemloLoader = true;
      let formData = new FormData();

      formData.append("loanTxnId", this.loanTxnId);

      /**
       * Merging the save or complete option here with wemlo invoice and options
       */
      formData.append("ifSave", ifSave);
      formData.append("options", JSON.stringify(this.voe.options));
      this.voe.uploadAuthorization.forEach(file => {
        formData.append("authorizationFile", file);
      });
      this.voe.uploadForm.forEach(file => {
        formData.append("uploadForm", file);
      });
      let response = await Axios.post(
        BASE_API_URL + "additionalTask/voe",
        formData);

      this.$snotify[
        response.data.status == 200
          ? "success"
          : response.data.status == 403
          ? "info"
          : "error"
      ](response.data.message);

      if (response.data.status == 200) {
        this.saveEvent();
      }

      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;

      this.$router.push({ path: "dashboard" });
    } catch (error) {
      console.log(error);
      const infectedFiles = this.checkResponseIfFileInfected(error);
      if (infectedFiles.length) {
        infectedFiles.forEach(filename => {
          this.removeInfectedFile(filename)
        })
      }
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
  public removeInfectedFile(filename: string): void {
    if (
      this.voe.uploadAuthorization &&
      !this.voe.uploadAuthorization.hasOwnProperty("path")
    ) {
      this.voe.uploadAuthorization = this.voe.uploadAuthorization.filter(file => (file.name !== filename));
    }
    if (
      this.voe.uploadForm &&
      !this.voe.uploadForm.hasOwnProperty("path")
    ) {
      this.voe.uploadForm = this.voe.uploadForm.filter(file => (file.name !== filename));
    }
  }

  public uploadAuthorization(event, type) {
    try {
      if(event.target.files.length>0){
      if (type == "authorization") {
        this.voe.uploadAuthorization = [];
        Object.values(event.target.files).forEach(element => {
          this.voe.uploadAuthorization.push(element);
        });
      } else {
        this.voe.uploadForm = [];
        Object.values(event.target.files).forEach(element => {
          this.voe.uploadForm.push(element);
        });
      }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public displayUploadAuthorization(allFiles,type) {
     try {
      if (allFiles[0].hasOwnProperty("path")) this.$refs.dfv["setInitialValuesForShowingSamplesWithDeleteButton"]( allFiles, null, null );
      else this.$refs.dfv["setInitialValuesForLocalFiles"](allFiles, true, {type:type});
    } catch (error) {
      console.log(error);
    }
  }
  private deleteDoc(data){
    this.voe[data.deleteData.type].splice(data.index, 1);
  }

  async mounted() {
    this.loanTxnId = this.$route.query.id;
    await this.fetchVoeTaskDetail();
  }
}
